// Bootrstrap variables

$navbar-default-bg: #ffecee;
$navbar-default-link-active-bg: #e0c6c9;
$navbar-default-border: #e0c6c9;
$navbar-default-brand-hover-color: #e0c6c9;

$input-border-focus: #E0C6C9;


@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

@import './include/helpers';

@import 'variables';
@import 'mixins';

@import 'include/modals';

@import 'sections';

@import 'include/photo';

@import 'include/browsers';

* {
  @include selection(black, #ffecee) {
    text-shadow: 0 0 3px #ffffff;
  }
}

.ng-hide.ng-hide-animate{
  display: none !important;
}

.cordova *:not(input, textarea) {
    -webkit-user-select: none; /* Disable selection/Copy of UIWebView */
    -webkit-touch-callout: none;
}

body {
  background-color: #ffecee;
  //background-image: radial-gradient(50% 118%, #FFFFFF  52%, #FFFDFD  71%, #FFECEE  100%);
  @include gradient-radial(white, #ffecee);
  background-position: 0;
  background-size: 100% 100%;
  font-family: 'mrseavesromanregular';
  min-height: 100vh;
  min-width: 740px;
  overflow-x: hidden;

  &.camera {
    background-image: url('../../img/background-camera.png');
    background-position: center top;
    background-color: transparent;
    background-size: auto;
  }
}


.change-language {
  background: none;
  border: none;
  padding: 0;
}

.background {
  left: 0;
  min-height: 100%;
  min-width: 100%;


  position: absolute;
  top: 0;
  z-index: -1;
}

.container-fluid .limit-width {
  margin-left: auto;
  margin-right: auto;
  max-width: 780px; // or 950px
  position: relative;
}

h1 {
  color: #000000;
  //font-family: mrseavessmallcapsregular;
  font-size: 27px;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0;
  text-align: center;
  //text-transform: uppercase;
}

h2 {
  color: #000000;
  //font-family: mrseavessmallcapsregular;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0;
  text-align: center;
  //text-transform: uppercase;
}


p {
  font-size: 18.2px;
}

%button-next-back {
  $img-width: 45px;
  $img-height: 45px;
  @include retina {
    background: url('../../img/Btn-Next@2x.png') no-repeat center center / $img-width $img-height;
  }
  background: url('../../img/Btn-Next.png') no-repeat center center / $img-width $img-height;
  display: block;
  height: $img-height;
  width: $img-width;
}

a,
div,
input,
button {
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
  }

  &.button {
    @include unselectable;

    background-color: rgba(255, 246, 249, .75);
    border-top-color: black;
    border-top-style: double;
    border-top-width: 3px;

    border-right-color: black;
    border-right-style: double;
    border-right-width: 3px;

    border-bottom-color: black;
    border-bottom-style: double;
    border-bottom-width: 3px;

    border-left-color: black;
    border-left-style: double;
    border-left-width: 3px;

    border-radius: 0;
    color: #000;
    display: block;
    font-family: mrseavesromanregular;
    font-size: 18.5px;
    margin: 0 auto;
    padding: 6px;
    text-align: center;
    text-transform: uppercase;
    transition: background .3s;
    //width: 174px;
    width: 100%;
    //height: 44px;
    //

    &:hover,
    &:focus {
      box-shadow: none;
      outline: 0 !important;
    }
  }

  &.button-next {
    @extend %button-next-back;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.button-back {
    @extend %button-next-back;
    filter: FlipH;
    left: 20px;
    position: absolute;
    top: 50%;
    transform: scaleX(-1) translateY(-50%);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  &.loading {
    cursor: progress;
    opacity: .5;
  }

  outline: 0;
}

.navbar-brand {
  & > img {
    margin-top: -5px;
    width: 110px;
  }
}

#cameraSwitch {
  background-image: url('../../img/camera-switch.png');
  background-size: 64px 64px;
  height: 64px;
  position: absolute;
  right: 10px;
  top: 5px;
  width: 64px;
}

.copyright {
  background-color: #FDEEEE;
  bottom: 0;
  color: #7F7373;
  font-size: 13.5px;
  height: 36px;
  margin: 0;
  padding: 9px;
  //position: absolute;
  text-align: center;
  width: 100%;
}

body.cordova #main {
  //padding-top: 20px;  // IPAD
}

body.ipad #main {
  padding-top: 20px;  // IPAD

  &:before {   // IPAD
    //background: rgba(224,198,201,0.5);
    //background: rgba(191, 164, 167, 0.67);
    background: rgba(171, 144, 147, 0.5);
    backdrop-filter: blur(10px);
    content: '';
    display: block;
    height: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1100;
  }
}

#main {
  //min-height: 1080px;
  position: relative;

  width: 100%;
  height: 100vh;
}

:not(.camera):not(.editor) > #main {
  overflow: hidden;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
}

input {
  &.form-control {
    text-align: center;
  }
}

#loading-bar {
  & .bar {
    background: #805055; //#9a8c8e
    bottom: 0;
    top: initial;

    & .peg {
      box-shadow: #805055 1px 0 6px 1px;
    }
  }
}

.legal-mentions  {
  text-decoration: underline;
}

.legal-terms-modal {
  max-height: 90vh;
  overflow-y: scroll;
}
