.home {
  .imagenHome {
    @include retina {
      background: url('../../img/imagenHome@2x.png') no-repeat center center / $imagen-home-width $imagen-home-height;
    }

    background: url('../../img/imagenHome.png') no-repeat center center / $imagen-home-width $imagen-home-height;
    height: $imagen-home-height;
    margin: 0 auto;
    max-width: $imagen-home-width;
    padding-left: 46px;
    padding-top: 217px;


    .callToAction {
      background: rgba(255, 255, 255, .8);
      padding: 25px;
      width: 385px;

      h1 {
        text-transform: initial;
      }

      p {
        color: #000000;
        font-family: mrseavesromanregular;
        font-size: 18.5px;
        letter-spacing: 0;
        line-height: 23px;
        margin: 19px 0;
        text-align: center;
      }
    }
  }
}
