.camera {
  #videoContainer {
    background: transparent;

    #video {
      background: transparent;
    }
  }
}


#videoContainer {
  background: white;
  height: 488px;
  margin: 20px auto;
  max-width: 600px;
  overflow: hidden;
  position: relative;
  width: 343px;

  #video {
    background: white;
    filter: FlipH;
    height: 100%;
    left: 50%;
    position: relative;
    transform: scaleX(-1) translateX(50%);
  }

  .silhouette-1 {
    @include retina {
      background: url('../../img/Silueta01@2x.png') center center / contain no-repeat;
    }
    background: url('../../img/Silueta01.png') center center / contain no-repeat;
    background-position: 50% 100px;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity .5s ease-in-out;
    width: 100%;
  }

  .silhouette-2 {
    @include retina {
      background: url('../../img/Silueta02@2x.png') center center / contain no-repeat;
    }
    background: url('../../img/Silueta02.png') center center / contain no-repeat;
    background-position: 50% 100px;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity .5s ease-in-out;
    width: 100%;
  }

  .silhouette-3 {
    @include retina {
      background: url('../../img/Silueta03@2x.png') center center / contain no-repeat;
    }
    background: url('../../img/Silueta03.png') center center / contain no-repeat;
    background-position: 50% 100px;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity .5s ease-in-out;
    width: 100%;
  }

  .show {
    opacity: .6;
  }
}
