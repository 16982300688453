$prefixes: ("-moz-", "");
@mixin selection($color, $background) {
    @each $prefix in $prefixes {
        ::#{$prefix}selection {
            color: $color;
            background: $background;
            @content;
        }
    }
}

@mixin unselectable {
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;

   /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
   -ms-user-select: none;
   user-select: none;
}