.be-a-swan {
  h1 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  h2 {
    color: #000000;
    //font-family: mrseavessmallcapsregular;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0;
    text-align: center;
    //text-transform: uppercase;
  }

  .camera-canvas {
    display: none;
  }

  .image-bottom-bar {
    background-color: white;
    background-image: linear-gradient(0deg, #F6F5F5 0, #FFF 31%, #EFEFEF 100%);
    height: 75px;
    left: 0;
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
  }

  a {
    &.button-shoot {
      @include retina {
        background: url('../../img/btnShoot@2x.png') no-repeat center center / 80px 80px;
      }
      @include unselectable;
      background: url('../../img/btnShoot.png') no-repeat center center / 80px 80px;
      display: block;
      height: 80px;
      margin: 0 auto;
      position: relative;
      transition: opacity .5s;
      width: 80px;
      z-index: 2;

      &.disabled {
        cursor: not-allowed;
        opacity: 0;
      }

      .hide {
        display: none;
      }
    }
  }

  .margin-bar {
    background-color: white;
    //box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .2);
    height: 35px;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    z-index: 1;
  }

  .bottom-header {
    background-color: #e0c6c9;
    height: 30px;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    width: auto;

    & .selector-arrow {
      backface-visibility: hidden;
      border-color: #e0c6c9 transparent transparent;
      border-style: solid;
      border-width: 10px 10px 0;
      bottom: -10px;
      content: '';
      height: 0;
      left: 50%;
      perspective: 1000;
      position: absolute;
      transform: translateX(-50%) translate3d(0, 0, 0);
      width: 0;
      z-index: 1;
    }

    & .selector-arrow-animated {
      transition: left .5s ease-in-out;
    }

    & > h2 {
      //font-family: mrseavessmallcapsregular;
      font-size: 15.5px;
      line-height: 31px;
      margin: 0;
      text-align: center;
      //text-transform: uppercase;
    }
  }

  .bottom-content {
    @include unselectable;
    background-color: #ffecee;
    height: 100px;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
  }

  .bottom-option {
    color: black;
    cursor: pointer;
    display: inline-block;
    height: 100px;
    //padding: 10px 0;
    width: 100px;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }


  %bottom-option-child-base {
    color: black;
    display: block;
    margin: 0 auto;

    margin-bottom: 10px;
    margin-top: 10px;
  }

  .wings-1 {
    @extend %bottom-option-child-base;

    @include retina {
      background: url('../../img/ico-AlasRepettoPose1@2x.png') no-repeat center center / 76px 57px;
    }

    background: url('../../img/ico-AlasRepettoPose1.png') no-repeat center center / 76px 57px;
    height: 57px;
    width: 76px;
  }

  .wings-2 {
    @extend %bottom-option-child-base;

    @include retina {
      background: url('../../img/ico-AlasRepettoPose2@2x.png') no-repeat center center / 29px 57px;
    }

    background: url('../../img/ico-AlasRepettoPose2.png') no-repeat center center / 29px 57px;
    height: 57px;
    width: 29px;
  }

  .wings-3 {
    @extend %bottom-option-child-base;

    @include retina {
      background: url('../../img/ico-AlasRepettoPose3@2x.png') no-repeat center center / 55px 57px;
    }

    background: url('../../img/ico-AlasRepettoPose3.png') no-repeat center center / 55px 57px;
    height: 57px;
    width: 55px;
  }

  @keyframes countdown-animation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }

    50% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(2);
    }
  }

  @keyframes flash-animation {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .countdown {
    //background: rgba(0, 0, 0, .5);
    bottom: 0px;
    color: white;
    font-family: 'mrseavessmallcapsregular';
    font-size: 35px;
    height: 100%;
    line-height: 40px;
    opacity: .9;
    position: absolute;
    text-align: center;
    width: 100%;

    & li {
      background: rgba(0, 0, 0, .25);
      display: block;
      font-size: 240px;
      height: 100%;
      opacity: 0;
      position: absolute;
      text-shadow: 0 0 3px black;
      transition: opacity .2s, transform 1.2s;

      width: 100%;

      &.active {
        &:after {
          animation-duration: 1.2s;
          animation-name: countdown-animation;
        }
        opacity: 1;
      }

      &:after {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .countdown-number-3 {
      &:after {
        content: '3';
        opacity: 0;
      }

    }

    .countdown-number-2 {
      &:after {
        content: '2';
        opacity: 0;
      }
    }

    .countdown-number-1 {
      &:after {
        content: '1';
        opacity: 0;
      }
    }

    .countdown-camera {
      background-color: white;
      opacity: 0;

      &.active {
        animation-duration: .2s;
        animation-name: flash-animation;
        opacity: 0;
      }
    }

  }
}


.camera {
  & .bottom-content.wings {
    margin-bottom: 18px;
  }
}
