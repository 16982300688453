header {
  background: #ffecee;
  position: relative;
  width: 100%;
  //z-index: 10000;

  &:after {
    @include gradient-vertical(#eddee1, #fff0f2);
    bottom: -5px;
    content: '';
    display: block;

    height: 5px;
    position: absolute;
    width: 100%;
  }

  &:before {
    display: block;
    content: ''
  }

  .logo {
    @include retina {
      background: url('../../img/repeto-logo@2x.png') no-repeat center center / $logo-width $logo-height;
    }

    background: url('../../img/repeto-logo.png') no-repeat center center / $logo-width $logo-height;
    display: block;
    height: $logo-height;
    margin: 0 auto;
    width: $logo-width;
  }

  .container-fluid {
    height: $header-height;
    margin-bottom: 20px;
    padding-top: ($header-height - $logo-height) / 2;
    position: relative;
  }
}
