.be-a-swan {
  &.editor {
    margin-bottom: 58px;

    .bottom-header {
      &.hacky {
        position: absolute;
        width: 100%;
      }
    }

    .uil-rolling-css {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(.3);
    }

    .bottom-content {
      &.hacky {
        margin-top: 30px;
        position: absolute;
        width: 100%;
        z-index: -1;
      }
    }

    .bottom-options {
      & .col-xs-6 .bottom-header,
      & .col-xs-6 .bottom-content {
        position: relative;
      }

      & .col-xs-6:first-child .bottom-header:after {
        background-color: #fff;
        content: '';
        height: 20px;
        margin: 5px 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }

      & .col-xs-6:first-child .bottom-content:after {
        background-color: #fff;
        content: '';
        height: 76px;
        margin: 12px 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }
    }

    & .editor-container {
      height: 598px;
      margin-bottom: 40px;
      margin-top: 50px;
      opacity: 0;
      position: relative;
      transition: opacity .5s ease-in-out;

      & .canvas-container {
        left: 50%;
        margin-top: -108px;
        position: absolute;
        transform: translateX(-50%);
      }

      & #editor-canvas {
        //background: url('../../img/tmp/editor.png') center center / 402px 664px no-repeat;
        //height: 664px;
        //width: 402px;

        // Revisar
        //margin-left: 27px;

      }
    }

    .image-bottom-bar {
      & .button {
        display: block;
        //margin-top: 20px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 250px;
        z-index: 2;
      }
    }

    %bottom-option-child-base {
      color: black;
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .filter-1 {
      @extend %bottom-option-child-base;

      @include retina {
        background: url('../../img/btn-filtro-old@2x.png') no-repeat center center / 32px 32px;
      }

      background: url('../../img/btn-filtro-old.png') no-repeat center center / 32px 32px;
      height: 57px;
      width: 32px;
    }

    .filter-2 {
      @extend %bottom-option-child-base;

      @include retina {
        background: url('../../img/btn-filtro-retro@2x.png') no-repeat center center / 32px 32px;
      }

      background: url('../../img/btn-filtro-retro.png') no-repeat center center / 32px 32px;
      height: 57px;
      width: 32px;
    }

    .filter-3 {
      @extend %bottom-option-child-base;

      @include retina {
        background: url('../../img/btn-filtro-pink@2x.png') no-repeat center center / 32px 32px;
      }

      background: url('../../img/btn-filtro-pink.png') no-repeat center center / 32px 32px;
      height: 57px;
      width: 32px;
    }
  }
}
