.publish {
  //padding-top: 73px;

  h1 {
    //margin-bottom: 113px;
  }

  .share-buttons {
    padding-top: 40px;

    a {
      font-size: 16px;
    }
  }

  .published-image {
    //background: url('/img/tmp/publish.png') center center / 618px 656px no-repeat;
    @include unselectable;
    height: 598px;
    left: 50%;
    margin-bottom: -75px;
    margin-left: 23px;
    //margin-top: -30px;
    position: relative;
    transform: translateX(-50%);

    // Revisar
  }

  .hashtag {
    color: #e6a780;
    font-size: 19px;
    margin: 0;
    padding: 10px 0;
    text-align: center;
    //text-transform: uppercase;
  }

  .bottom-content {
    margin-bottom: 100px;
  }

}
