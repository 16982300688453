.the-postures {
  h1  {
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  section {
    margin-top: 50px;
  }

  .first-position-picture {
    @include retina {
      background: url('../../img/frame-posicion01@2x.png') no-repeat center center / 139px 139px;
    }

    background: url('../../img/frame-posicion01.png') no-repeat center center / 139px 139px;
    float: left;
    height: 139px;
    margin-right: 20px;
    width: 139px;
  }

  .second-position-picture {
    @include retina {
      background: url('../../img/frame-posicion02@2x.png') no-repeat center center / 139px 139px;
    }

    background: url('../../img/frame-posicion02.png') no-repeat center center / 139px 139px;
    float: left;
    height: 139px;
    margin-right: 20px;
    width: 139px;
  }

  .third-position-picture {
    @include retina {
      background: url('../../img/frame-posicion03@2x.png') no-repeat center center / 139px 139px;
    }

    background: url('../../img/frame-posicion03.png') no-repeat center center / 139px 139px;
    float: left;
    height: 139px;
    margin-right: 20px;
    width: 139px;
  }
}
