.the-fragance {
  h1 {
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .the-fragance-image {
    @include retina {
      background: url('../../img/the-fragance@2x.jpg') no-repeat center center / 557px 456px;
    }
    background: url('../../img/the-fragance.jpg') no-repeat center center / 557px 456px;
    height: 456px;
    margin: 0 auto;
    width: 557px;
  }

  h2 {
    margin: 10px 0;
    text-align: center;
  }

  .call-to-action {
    margin-top: 20px;
    width: 40%;
  }

  p {
    font-size: 20.2px;
  }
}
