.how-to-be-a-swan {
  text-align: center;

  h1 {
    margin-bottom: 23px;
    margin-top: 22px;
    text-transform: uppercase;
  }

  .row {
    //margin-bottom: 20px;
    //margin-top: 20px;
  }

  .number {
    @include unselectable;
    background: #FFFFFF;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0 , .1);
    color: #000000;
    cursor: default;
    float: left;
    font-size: 36px;
    height: 58px;
    letter-spacing: 0;
    line-height: 25px;
    margin: 20px 20px 20px 0;
    padding: 15px;
    text-align: center;
    width: 58px;
  }

  h2 {
    //font-family: mrseavessmallcapsregular;
  }

  a,
  div {
    &.option-facebook {
      $img-width: 24px;
      $img-height: 24px;
      @include retina {
        background: $a-button-background-color url('../../img/ico-button-facebook@2x.png') no-repeat 10px center / $img-width $img-height;
      }
      background: $a-button-background-color url('../../img/ico-button-facebook.png') no-repeat 10px center / $img-width $img-height;
      padding-left: $img-width + 20px;
    }

    &.option-computer {
      $img-width: 32px;
      $img-height: 21px;
      @include retina {
        background: $a-button-background-color url('../../img/ico-button-computer@2x.png') no-repeat 10px center / $img-width $img-height;
      }
      background: $a-button-background-color url('../../img/ico-button-computer.png') no-repeat 10px center / $img-width $img-height;
      overflow: hidden;
      padding-left: $img-width + 20px;

      position: relative;

      input[type=file] {
        cursor: pointer;
        filter: alpha(opacity=0);
        font-size: 20px;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    &.option-webcam {
      $img-width: 24px;
      $img-height: 21px;
      @include retina {
        background: $a-button-background-color url('../../img/ico-button-webcam@2x.png') no-repeat 10px center / $img-width $img-height;
      }
      background: $a-button-background-color url('../../img/ico-button-webcam.png') no-repeat 10px center / $img-width $img-height;
      padding-left: $img-width + 20px;
    }
  }

  .options {
    margin: 30px auto;
    max-width: 632px;
    width: 100%;

    .button {
      display: table-cell;
      font-size: 16px;
      height: 52px !important;
      line-height: 16px;

      text-align: left;
      vertical-align: middle;

      padding-top: 7px;
      padding-bottom: 7px;

      &.text-center {
        text-align: center;

        display: block;
        padding-top: 16px;
        padding-bottom: 16px;

        width: 100%;
      }
    }
  }

  .step-two {
    $img-margin-right: 42px;
    float: left;
    margin-top: -15px;
    max-width: 632px;
    width: 100%;

    .filter-one {
      $img-width: 76px;
      $img-height: 74px;
      @include retina {
        background: url('../../img/ico-tiradores-editor@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/ico-tiradores-editor.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      width: $img-width;
    }

    .filter-two {
      $img-width: 76px;
      $img-height: 57px;
      @include retina {
        background: url('../../img/ico-AlasRepettoPose1@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/ico-AlasRepettoPose1.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      margin-top: 18px;
      width: $img-width;
    }

    .filter-three {
      $img-width: 29px;
      $img-height: 57px;
      @include retina {
        background: url('../../img/ico-AlasRepettoPose2@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/ico-AlasRepettoPose2.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      margin-top: 18px;
      width: $img-width;
    }

    .filter-four {
      $img-width: 55px;
      $img-height: 57px;
      @include retina {
        background: url('../../img/ico-AlasRepettoPose3@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/ico-AlasRepettoPose3.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      margin-top: 18px;
      width: $img-width;
    }

    .filter-five {
      $img-width: 32px;
      $img-height: 32px;
      @include retina {
        background: url('../../img/btn-filtro-old@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/btn-filtro-old.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      margin-top: 31px;
      width: $img-width;
    }

    .filter-six {
      $img-width: 32px;
      $img-height: 32px;
      @include retina {
        background: url('../../img/btn-filtro-retro@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/btn-filtro-retro.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      margin-top: 31px;
      width: $img-width;
    }

    .filter-seven {
      $img-width: 32px;
      $img-height: 32px;
      @include retina {
        background: url('../../img/btn-filtro-pink@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/btn-filtro-pink.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      margin-top: 31px;
      width: $img-width;
    }
  }

  .step-three {
    $img-margin-right: 42px;
    height: 139px;
    margin: 20px auto;
    width: 379px;

    .img-frame {
      $img-width: 139px;
      $img-height: 139px;
      @include retina {
        background: url('../../img/frame-posicion03@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/frame-posicion03.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      //margin-top: 31px;
      width: $img-width;
    }

    .img-share {
      $img-width: 16px;
      $img-height: 17px;
      @include retina {
        background: url('../../img/ico-share@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/ico-share.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      margin-top: 58px;
      width: $img-width;
    }

    .img-facebook {
      $img-width: 28px;
      $img-height: 29px;
      @include retina {
        background: url('../../img/ico-facebook@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/ico-facebook.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      margin-top: 52px;
      width: $img-width;
    }

    .img-mail {
      $img-width: 28px;
      $img-height: 22px;
      @include retina {
        background: url('../../img/ico-envelope@2x.png') no-repeat center center / $img-width $img-height;
      }
      background: url('../../img/ico-envelope.png') no-repeat center center / $img-width $img-height;
      float: left;
      height: $img-height;
      margin-right: $img-margin-right;
      margin-top: 56px;
      width: $img-width;
    }
  }

  .intro-photo-frame {
    $img-width: 603px;
    $img-height: 332px;
    @include retina {
      background: url('../../img/intro-photoFrame@2x.png') no-repeat center center / $img-width $img-height;
    }
    background: url('../../img/intro-photoFrame.png') no-repeat center center / $img-width $img-height;
    height: $img-height;
    margin: 40px auto 0;
    width: $img-width;
  }

  .add-your-photo {
    margin-bottom: 40px;
    margin-top: 47px;

  }
}
