.facebook-gallery {
  h2 {
    font-family: mrseavesromanregular;
    font-size: 18.5px;
    margin-top: 10px;
    text-align: center;
  }

  .gallery-selection {
    height: 538px;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow-y: scroll;
    width: 568px;   // TODO: revertir a 538px cuando imlpementemos las scrollbars


    .row {
      margin-bottom: 5px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 5px;

      .clearfix {
        margin: 15px auto;
      }

      article {
        background-color: #d8d8d8;
        background-position: center center;
        background-size: cover;
        border: 1px solid #979797;
        cursor: pointer;
        height: 130px;
        margin-bottom: 15px;
        margin-right: 14px;
        position: relative;
        width: 123px;

        &[disabled] {
          cursor: not-allowed;
          opacity: .5;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }

        &:nth-last-child(4),
        &:nth-last-child(3),
        &:nth-last-child(2),
        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        &.selected:after {
          @include retina {
            background: url('../../img/ico-checked@2x.png') bottom right / 35px 35px no-repeat;
          }
          background: url('../../img/ico-checked.png') bottom right / 35px 35px no-repeat;
          border: 1px solid #4e5871;
          content: '';
          cursor: default;
          display: block;
          height: 130px;
          left: -1px;
          position: absolute;
          top: -1px;
          width: 123px;
        }
      }
    }
  }

  .button {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
  }
}
