body {
  &.outdated-browser {
    min-width: none; } }

.outdated-browser {
  h1 {
    margin: 40px 0;
    font-size: 40px; }

  h2 {
    margin: 20px 0; }

  ul {
    padding: 0;
    margin: 40px 0;

    text-align: center;

    li {
      display: inline-block;
      width: 100px;
      height: 100px;
      text-align: center;
      margin: 20px 20px 40px 20px;

      a {
        color: black;
        display: block;
        transition: transform .2s ;

        &:hover {
          transform: scale(1.2); } }

      span {
        width: 100px;
        height: 100px;
        display: block; } } }


  .browser {
    span {
      background-image: url('../../img/browsers-bg.png');
      background-size: auto 200px; }

    &.chrome {
      span {
        background-position: 0px -100px; } }

    &.firefox {
      span {
        background-position: -100px -100px; } }

    &.iexplore {
      span {
        background-position: -200px -100px; } }

    &.safari {
      span {
        background-position: -300px -100px; } }

    &.opera {
      span {
        background-position: -400px -100px; } } } }
