@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}


@font-face {
    font-family: 'mrseavesboldbold';
    src: url('../../fonts/mrs-eaves-bold-webfont.eot');
    src: url('../../fonts/mrs-eaves-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/mrs-eaves-bold-webfont.woff') format('woff'),
         url('../../fonts/mrs-eaves-bold-webfont.ttf') format('truetype'),
         url('../../fonts/mrs-eaves-bold-webfont.svg#mrseavesboldbold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'mrseavesitalicitalic';
    src: url('../../fonts/mrs-eaves-italic-webfont.eot');
    src: url('../../fonts/mrs-eaves-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/mrs-eaves-italic-webfont.woff') format('woff'),
         url('../../fonts/mrs-eaves-italic-webfont.ttf') format('truetype'),
         url('../../fonts/mrs-eaves-italic-webfont.svg#mrseavesitalicitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'mrseavesromanregular';
    src: url('../../fonts/mrs-eaves-roman-regular-webfont.eot');
    src: url('../../fonts/mrs-eaves-roman-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/mrs-eaves-roman-regular-webfont.woff') format('woff'),
         url('../../fonts/mrs-eaves-roman-regular-webfont.ttf') format('truetype'),
         url('../../fonts/mrs-eaves-roman-regular-webfont.svg#mrseavesromanregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'mrseavessmallcapsregular';
    src: url('../../fonts/mrs-eaves-small-caps-regular-webfont.eot');
    src: url('../../fonts/mrs-eaves-small-caps-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/mrs-eaves-small-caps-regular-webfont.woff') format('woff'),
         url('../../fonts/mrs-eaves-small-caps-regular-webfont.ttf') format('truetype'),
         url('../../fonts/mrs-eaves-small-caps-regular-webfont.svg#mrseavessmallcapsregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
