nav {
  &.indexFooter {
    height: $footer-home-height;
    margin-bottom: 1px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    max-width: $imagen-home-width;

    .banner-posturas {
      @include retina {
        background: url('../../img/banner-posturas@2x.png') no-repeat center center / $footer-home-width $footer-home-height;
      }
      background: url('../../img/banner-posturas.png') no-repeat top left / $footer-home-width $footer-home-height;
      height: $footer-home-height;
      margin-right: 20px;
      padding-left: 0;
      padding-top:  ($footer-home-height / 2) - ($a-button-height / 2);
      width: $footer-home-width;
      //float: left;
    }

    .banner-the-fragance {
      @include retina {
        background: url('../../img/Banner-the-fragrance@2x.png') no-repeat center center / $footer-home-width $footer-home-height;
      }
      background: url('../../img/Banner-the-fragrance.png') no-repeat top left / $footer-home-width $footer-home-height;
      height: $footer-home-height;
      padding-left: 0;
      padding-top:  ($footer-home-height / 2) - ($a-button-height / 2);
      width: $footer-home-width;
      //float: left;
    }

    .banner-posturas a.button,
    .banner-the-fragance a.button {
      /*left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);*/
      width: 60%;
    }
  }
}
