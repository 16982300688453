.modal.fade {
  //opacity: 1;
}

.modal.fade .modal-dialog, .modal.in .modal-dialog {
  transform: translate(0, 0);
}


/*.modal-open .modal {
  //padding: 80px 0;
  //padding: 40px 0;
  height: initial;
  overflow-y: hidden;
  //padding: 20px 0;
}*/

/*#modal-container {
  height: 100vh;
  position: absolute;
}*/


.modal-backdrop {
  background-color: $modal-backdrop-background;
  
  //background-color: transparent;

  &.in {
    backdrop-filter: blur(10px);
    opacity: 1;
  }
}


.body.cordova .modal-backdrop,
.body.ipad .modal-backdrop {
  background-color: $modal-backdrop-background-ipad;
}

.modal-dialog  {
  .modal-content {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);

    padding: 30px;

  }

  a {
    &.modal-close {
      @include retina {
        background: url('../../img/ico-close@2x.png') no-repeat center center / 18px 17px;
      }
      background: url('../../img/ico-close.png') no-repeat center center / 18px 17px;
      display: block;
      height: 17px;
      overflow: hidden;
      position: absolute;
      right: 12px;
      text-indent: 100%;
      top: 12px;
      white-space: nowrap;
      width: 18px;
    }
  }

  h1 {
    //text-transform: uppercase;
  }

  h2 {
    //font-family: mrseavessmallcapsregular;
    margin: 5px 0;
  }
}

.center-modal {
  .modal-dialog {
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }
}
